<script lang='ts'>
  import { onMount } from 'svelte'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { findLangById } from '@/helpers/findHelpers'
  import { _ } from '@/helpers/i18n'

  import Flag from '@/components/forum/Flag.svelte'
  import CloseButton from '@/components/ui/CloseButton.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import { userStorredConfig } from '@/store'

  export let editingInterfaceLanguage = true

  let language = 0
  let variant = ''
  let isEditing = false

  let rand = Math.random()
  const getData = async () => {
    const data = await fetchData('social/interfaceLanguage', {
      language: 0
    })
    language = data.language || 0
    variant = data.variant || ''
  }

  onMount(() => {
    getData()
  })

  const langVariants: Record<number, string[]> = {
    2: ['', 'cyr']
  }
  const variantNames: Record<string, string> = {
    '': 'Latin',
    cyr: 'Cyrillic'
  }
  const saveLanguage = async () => {
    userStorredConfig.setKeys({
      id_lang_interface: language,
      interface_variant: variant
    })
    if (!langVariants[language]) variant = ''
    await fetchData('social/interfaceLanguage', { language, variant })
    rand = Math.random()
    isEditing = false
    editingInterfaceLanguage = false
  }
</script>
<div class='interface-wrapper _vertical-small'>
  {#if !isEditing}
    <span>{$_('profile.currentInterfaceIs')}:</span>
    {#if language}
      <div class='interface-language'>
        <span>{findLangById(language)}</span>
        <Flag id={language} {rand} />
      </div>
    {/if}
    {#if variant} {variantNames[variant] || ''} {/if}
    <CloseButton closeVariant='absolute' onClick={() => {editingInterfaceLanguage = false}} />
    <button type='button' on:click={() => { isEditing = true }}>{$_('mix.edit')}</button>
  {:else}
    <form class='_form _vertical-small' action='' on:submit|preventDefault={saveLanguage}>
      <h3>{$_('profile.newInterfaceLanguage')}</h3>
      <CloseButton closeVariant='absolute' onClick={() => { isEditing = false }} />
      <LanguageSelect type='all' bind:selectedLanguage={language} />
      {#if langVariants[language]?.length > 1}
        <h3>{$_('profile.chooseVariant')}</h3>
        <select bind:value={variant}>
          {#each langVariants[language] as langVariant}
            <option value={langVariant}>{variantNames[langVariant]}</option>
          {/each}
        </select>
      {/if}
      <div class='buttons-wrapper'>
        <input type='submit' value={$_('mix.save')} />
      </div>
    </form>
  {/if}
</div>

<style lang='scss'>
  .interface-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .buttons-wrapper {
    display: flex;
    gap: 1.6rem;
  }

  .interface-language {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    align-self: center;
    width: fit-content;
    padding: 1.6rem 2.4rem;
    box-shadow: var(--Shadow-Z);

    > :global(.flag-wrapper svg) {
      cursor: text;
    }
  }
</style>
